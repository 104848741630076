<template>
  <section data-bs-version="5.1" class="form5 cid-uiYZFyK2BJ" id="contact-form-3-uiYZFyK2BJ">
    
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 content-head">
                <div class="mbr-section-head mb-5">
                    <h3 class="mbr-section-title mbr-fonts-style align-center mb-0 display-2"><strong>Отправьте сообщение владельцу: {{ count }}</strong></h3>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-8 mx-auto mbr-form" data-form-type="formoid">
                <form action="https://mobirise.eu/" method="POST" class="mbr-form form-with-styler" data-form-title="Form Name"><input type="hidden" name="email" data-form-email="true" value="wJ/Pcuh4x2EZvK+fRJlx1RKjtqrj1qGhZ1Jl6yv00PlDrGrcBNar2479i8CvyljYY5g+ZJGNBCOc+wRvvmS+7rbWXEgQ4DBERgpEJHRnfmkvUM3WcxvFY7YpCPUd/5MP">
                    <div class="row">
                        <div hidden="hidden" data-form-alert="" class="alert alert-success col-12">Спасибо, сообщение отправлено владельцу!</div>
                        <div hidden="hidden" data-form-alert-danger="" class="alert alert-danger col-12">
                            Oops...! some problem!
                        </div>
                    </div>
                    <div class="dragArea row">
                        
                        
                        
                        <div class="col-12 form-group mb-3" data-for="textarea">
                            <textarea name="textarea" placeholder="Сообщение" data-form-field="textarea" class="form-control" id="textarea-contact-form-3-uiYZFyK2BJ"></textarea>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 align-center mbr-section-btn">
                            <button type="submit" class="btn btn-primary display-7">Отправить</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 content-head">
                <div class="mbr-section-head mb-5">
                    <h4 class="mbr-section-subtitle mbr-fonts-style align-center mb-0 mt-4 display-7">Либо нажмите кнопоку ниже.</h4>
                </div>
            </div>
        </div>
    </div>
</section>

<section data-bs-version="5.1" class="people01 cid-uiZmHRXzaq" id="people01-2">
  

  

  <div class="container">
    <div class="row">
      <div class="col-lg-5 mb-5">
        
        

        
      </div>

      <div class="col-lg-7">
        <div class="item features-without-image">
          <div class="item-wrapper">
            <div class="row align-items-center">
              <div class="col-md-7">
                <div class="align-center">
                  <h3 class="mbr-section-title mbr-fonts-style mb-2 display-5"><strong>Мешает машина</strong></h3>
                  
                  <p class="mbr-section-text mbr-fonts-style mb-0 display-7">Ваша машина мешает проезду, срочно подойдите!</p>
                </div>
              </div>

              <div class="col-md-5">
                <div class="mbr-section-btn align-center"><a class="btn btn-danger-custom display-7" href="#">Оповестить!<strong></strong></a></div>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  </div>
</section>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      count: 3
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
